import React from "react";
import PropTypes from "prop-types";
import { PropertyEditForms } from "../molecules/PropertyEditForms";
import { useForm, FormProvider } from "react-hook-form";
import { TextField } from "../atoms/TextField";
import { Box, Divider, Grid, InputAdornment, Paper, Stack, Typography } from "@mui/material";
import { AddButton } from "../atoms/AddButton";
import { SelectForm } from "../atoms/SelectForm";
import { DeleteButton } from "../atoms/DeleteButton";
import { HiddenInput } from "../atoms/HiddenInput";
import { isMobile } from "react-device-detect";
import { PropertyEditFormDialog } from "./PropertyEditFormDialog";
import { LoadingProgress } from "../atoms/LoadingProgress";
import * as validationRules from "../../utils/ValidationRules";
import { useFocusInput, useInitSalesPlan, useInitSalesPlanDetail } from "../../utils/CustomHook";
import { createSalesPlan, onBlurCommonAction, overwriteSalesPlanDetail, removeChild, saveChildForPropertyEdit } from "../../utils/PropertyEdit";
import { CopyButton } from "../atoms/CopyButton";

export const SalesPlanForms = React.memo(function salesPlanForms({
  property,
  initialized,
  salesplan,
  specificationArea,
  setOriginPropertyData,
  toggleViewport,
  formPaperVariant,
  formPaperElevation,
  ...props
}) {
  const [isLoading, setIsLoading] = React.useState(false)
  const loadingComplete = React.useCallback(() => {
    setIsLoading(false)
  }, [])

  const [modalSalesPlanFormIndex, setModalSalesPlanFormIndex] = React.useState(undefined)
  const [isOpenHeader, setIsOpenHeader] = React.useState(false)
  const [isOpenTotal, setIsOpenTotal] = React.useState(false)

  const [choices, setChoices] = React.useState({});
  const [propertyId, setPropertyId] = React.useState(null)

  const [isFocused, setFocusInputName] = useFocusInput()
  const handleFocus = React.useCallback((name) => {
    setFocusInputName(name)
  }, [])

  const {
    control,
    formState: { errors },
    formState,
    getValues,
    methods,
    setError,
    clearErrors,
    setValue,
    trigger,
  } = useForm({
    shouldFocusError: false,
    criteriaMode: "all",
    defaultValues: "",
    mode: "onChange",
  });

  const [salesPlanDetailFields, salesPlanDetailAppend, salesPlanDetailRemove] = useInitSalesPlanDetail(salesplan.salesplandetail_set, setValue, isFocused, control, trigger)
  useInitSalesPlan(salesplan, setValue, isFocused)

  const needResalePriceIncludingBuildingMaxValidation = React.useCallback((is_grade_ls = undefined) => {
    if (!property.is_areacode_capital_area || !is_grade_ls) {
      return false
    }
    return ![37, 38, 39, 40].includes(specificationArea)
  }, [property, specificationArea])

  React.useEffect(() => {
    salesPlanDetailFields.map((item, index) => {
      const name = `salesplan.salesplandetail_set.${index}.resale_price_including_building`
      trigger(name)
    })
  }, [specificationArea])

  React.useEffect(() => {
    const currentErrors = {...formState.errors}
    currentErrors?.salesplan?.salesplandetail_set?.map((item, index) => {
      if (item.resale_price_including_building === undefined || item.resale_price_including_building.type !== "max") {
        return
      }
      const name = `salesplan.salesplandetail_set.${index}.resale_price_including_building`
      if (isFocused(name) || !getValues(name)) {
        return
      }
      if (needResalePriceIncludingBuildingMaxValidation(isGradeLsForSalesPlanDetail(index))) {
        const params = {
          target: {
            name: name,
            value: "",
          }
        }
        saveSalesPlanDetail(params)
        setValue(name, "", { shouldValidate: false })
      }
    })
  }, [formState]);

  React.useEffect(() => {
    setPropertyId(property.id)
  }, [property])

  React.useEffect(() => {
    setChoices(props.choices);
  }, [props.choices]);

  React.useEffect(() => {
    if (!(salesplan)) {
      return;
    }

    initialized();
  }, [salesplan]);

  const saveCommonParams = React.useMemo(() => [
    propertyId, clearErrors, setOriginPropertyData, setError
  ], [propertyId, clearErrors, setOriginPropertyData, setError])

  const removeChildCommonParams = React.useMemo(() => [
    propertyId, getValues, setOriginPropertyData,
  ], [propertyId, getValues, setOriginPropertyData])

  const handleCreateSalesPlan = (e) => {
    e.stopPropagation();
    const successCallBack = () => {
      salesPlanDetailAppend()
      if (modalSalesPlanFormIndex !== undefined) {
        setModalSalesPlanFormIndex(undefined)
        toggleViewport()
      }
    }
    createSalesPlan(...saveCommonParams, successCallBack)
  };

  const onBlurForSalesPlan = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    const saveChildKeys = [
      "property", "salesplan",
    ]
    saveChildForPropertyEdit(e, errors,...saveCommonParams, ...saveChildKeys)
  }, [propertyId]);

  const onBlurForSalesPlanDetail = React.useCallback((e) => {
    onBlurCommonAction(setFocusInputName)
    saveSalesPlanDetail(e, errors)
  }, [propertyId]);

  ////BP面積が変更されたときにBPが有or無に自動で変更される処理
  const onBlurForSalesPlanDetailBpTsubo = React.useCallback((e) =>{
    onBlurCommonAction(setFocusInputName)
    saveSalesPlanDetail(e, errors)

  }, [propertyId])

  const saveSalesPlanDetail = React.useCallback((e, errors = {}) => {
    const saveChildKeys = [
      "sales_plan", "salesplan", "salesplandetail_set"
    ]
    saveChildForPropertyEdit(e, errors, ...saveCommonParams, ...saveChildKeys, getValues)
  }, [propertyId])

  const handleRemoveForSalesPlan = React.useCallback((index) => {
    removeChild(...removeChildCommonParams, salesPlanDetailRemove, index, "salesplan", "salesplandetail_set", "sales_plan")
    if (modalSalesPlanFormIndex !== undefined) {
      setModalSalesPlanFormIndex(undefined)
      toggleViewport()
    }
  }, [propertyId, modalSalesPlanFormIndex]);

  const handleOverwriteForSalesPlan = React.useCallback((index) => {
    const destSalesPlanDetailId = getValues(`salesplan.salesplandetail_set.${index}.id`)
    overwriteSalesPlanDetail(propertyId, destSalesPlanDetailId, setOriginPropertyData)
  }, [propertyId]);

  const rules = React.useCallback((is_grade_ls = undefined) => {
    const data = {
      salesplan: {
        center_manager_name: {
          onBlur: onBlurForSalesPlan,
        },
        sales_manager_name: {
          onBlur: onBlurForSalesPlan,
        },
        salesplandetail_set: {
          grade: {
            onBlur: onBlurForSalesPlanDetail,
          },
          building_no: {
            onBlur: onBlurForSalesPlanDetail,
          },
          section: {
            onBlur: onBlurForSalesPlanDetail,
          },
          floor_number: {
            onBlur: onBlurForSalesPlanDetail,
          },
          room_layout: {
            onBlur: onBlurForSalesPlanDetail,
          },
          land_area: {
            onBlur: onBlurForSalesPlanDetail,
          },
          building_tsubo: {
            onBlur: onBlurForSalesPlanDetail,
          },
          resale_price_including_building: {
            onBlur: onBlurForSalesPlanDetail,
          },
          rb: {
            onBlur: onBlurForSalesPlanDetail,
          },
          garage: {
            onBlur: onBlurForSalesPlanDetail,
          },
          bp: {
            onBlur: onBlurForSalesPlanDetail,
          },
          bp_tsubo:{
            onBlur: onBlurForSalesPlanDetailBpTsubo,
          },
          direction: {
            onBlur: onBlurForSalesPlanDetail,
          },
          dive: {
            onBlur: onBlurForSalesPlanDetail,
          },
          center_manager_price: {
            onBlur: onBlurForSalesPlanDetail,
          },
          sales_manager_price: {
            onBlur: onBlurForSalesPlanDetail,
          }
        },
      }
    }
    let building_tsubo_min = null
    if (property.is_areacode_capital_area) {
      building_tsubo_min = is_grade_ls ? 18.15 : null
    } else if (property.is_areacode_gunma) {
      building_tsubo_min = is_grade_ls ? 24.2 : null
    } else if (property.is_areacode_fukuoka || property.is_areacode_nagoya) {
      building_tsubo_min = is_grade_ls ? 24.2 : 19
    } else if (property.is_areacode_kansai) {
      building_tsubo_min = is_grade_ls ? 25 : 18.15
    }
    data.salesplan.salesplandetail_set.building_tsubo = {
      ...data.salesplan.salesplandetail_set.building_tsubo,
      ...validationRules.min(building_tsubo_min),
    }

    let resale_price_including_building_max = null
    if (needResalePriceIncludingBuildingMaxValidation(is_grade_ls)) {
      resale_price_including_building_max = 5080
    }
    data.salesplan.salesplandetail_set.resale_price_including_building = {
      ...data.salesplan.salesplandetail_set.resale_price_including_building,
      ...validationRules.max(resale_price_including_building_max),
    }
    return data
  }, [onBlurForSalesPlan, onBlurForSalesPlanDetail, onBlurForSalesPlanDetailBpTsubo,property, specificationArea]);

  const labels = React.useMemo(() => ({
    grade: "PS/LS",
    building_no: "号棟",
    section: "区画",
    floor_number: "階数",
    rb: "RB",
    garage: "車庫",
    bp: "BP",
    bp_tsubo: "BP面積(坪)",
    direction: "向き",
    room_layout: "間取り",
    dive: "潜り",
    land_tsubo: "土地(坪)",
    land_area: "土地(㎡)",
    building_tsubo: "建物(坪)",
    building_area: "建物(㎡)",
    resale_price_including_building: "建込再販価格",
    land_price: "土地価格",
    land_unit_price: "土地単価",
    total_floor_unit_price: "延床単価",
    building_price: "建物価格",
    construction_price: "建築価格",
    center_manager_price: "センター長",
    sales_manager_price: "営業部長",
  }), [])

  const AddSalesPlanButton = () => {
    return (
      <AddButton onClick={handleCreateSalesPlan} disabled={props.disabled || salesplan.MAX_ROW_SALES_PLAN <= salesPlanDetailFields.length} />
    )
  }

  const isGradeLsForSalesPlanDetail = React.useCallback((index) => {
    const result = salesplan?.salesplandetail_set?.[index]?.is_grade_ls
    return typeof result === "boolean" ? result : false
  }, [salesplan])

  const propertyEditFormDialogCloseAction = React.useCallback(() => {
    toggleViewport()
  }, [])
  const formAreaClickedAction = React.useCallback((additionalProcess) => {
    if (!isMobile) {
      return
    }
    if (additionalProcess) {
      additionalProcess()
    }
    setIsLoading(true)
    toggleViewport()
  }, [])

  const commonInputProps = React.useMemo(() => ({
    control: control,
    onFocus: handleFocus,
  }), [control, handleFocus])

  return (
    <PropertyEditForms
      title={
        <>
          販売計画
          <AddSalesPlanButton/>
        </>
      }
    >
      <LoadingProgress open={isLoading} useCircular={false} />
      <Box sx={{overflowX: "scroll"}}>
        <Box sx={{ minWidth: "1500px", pb: 2 }}>
          <FormProvider {...methods}>
            <PropertyEditFormDialog
              isOpen={isOpenHeader}
              handleClose={() => {
                setIsOpenHeader(false)
                propertyEditFormDialogCloseAction()
              }}
              openedAction={loadingComplete}
              title="販売計画"
              content={
                <SalesPlanHeaderForm
                  commonInputProps={commonInputProps}
                  rules={rules()}
                  labels={labels}
                  disabled={props.disabled}
                  isModal={true}
                />
              }
            />
            <PropertyEditFormDialog
              isOpen={modalSalesPlanFormIndex !== undefined}
              handleClose={() => {
                setModalSalesPlanFormIndex(undefined)
                propertyEditFormDialogCloseAction()
              }}
              openedAction={loadingComplete}
              title={
                <Stack direction="row" justifyContent="space-between" sx={{ width: "100%" }}>
                  販売計画
                  <AddSalesPlanButton/>
                </Stack>
              }
              content={
                <SalesPlanForm
                  commonInputProps={commonInputProps}
                  labels={labels}
                  rules={rules(isGradeLsForSalesPlanDetail(modalSalesPlanFormIndex))}
                  index={modalSalesPlanFormIndex}
                  choices={choices}
                  disabled={props.disabled}
                  setValue={setValue}
                  getValues={getValues}
                  handleRemoveForSalesPlan={handleRemoveForSalesPlan}
                  handleOverwriteForSalesPlan={handleOverwriteForSalesPlan}
                  isModal={true}
                />
              }
            />
            <PropertyEditFormDialog
              isOpen={isOpenTotal}
              handleClose={() => {
                setIsOpenTotal(false)
                propertyEditFormDialogCloseAction()
              }}
              openedAction={loadingComplete}
              title="販売計画合計"
              content={
                <SalesPlanTotal
                  commonInputProps={commonInputProps}
                  isModal={true}
                  labels={labels}
                />
              }
            />
            <form>
              <Grid container direction="row" spacing={2}>

                <Grid item xs={12}>
                  <Paper
                    elevation={formPaperElevation}
                    variant={formPaperVariant}
                    onClick={() => formAreaClickedAction(() => setIsOpenHeader(true))}
                  >
                    <Grid container direction="row" spacing={1} columns={13}>
                      <Grid item xs={10}/>
                      <Grid item xs={2}>
                        <Box>
                          <Typography variant="subtitle1">
                            販売可能価格ヒアリング
                          </Typography>
                          <SalesPlanHeaderForm
                            commonInputProps={commonInputProps}
                            rules={rules()}
                            labels={labels}
                            disabled={props.disabled}
                            isModal={false}
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={1}/>
                    </Grid>
                  </Paper>
                </Grid>

                {salesPlanDetailFields.map((item, index) => {
                  const isGradeLs = isGradeLsForSalesPlanDetail(index)
                  return(
                    <Grid item xs={12} key={item.id}>
                      <Stack spacing={1}>
                        <Paper
                          elevation={formPaperElevation}
                          variant={formPaperVariant}
                          onClick={() => formAreaClickedAction(() => setModalSalesPlanFormIndex(index))}
                        >
                          <SalesPlanForm
                            commonInputProps={commonInputProps}
                            rules={rules(isGradeLs)}
                            labels={labels}
                            index={index}
                            choices={choices}
                            disabled={props.disabled}
                            setValue={setValue}
                            getValues={getValues}
                            handleRemoveForSalesPlan={handleRemoveForSalesPlan}
                            handleOverwriteForSalesPlan={handleOverwriteForSalesPlan}
                            isModal={false}
                          />
                        </Paper>
                        <Divider/>
                      </Stack>
                    </Grid>
                  )
                })}

                <Grid item xs={12}>
                  <Paper
                    elevation={formPaperElevation}
                    variant={formPaperVariant}
                    onClick={() => formAreaClickedAction(() => setIsOpenTotal(true))}
                  >
                    <SalesPlanTotal
                      commonInputProps={commonInputProps}
                      isModal={false}
                      labels={labels}
                    />
                  </Paper>
                </Grid>

              </Grid>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </PropertyEditForms>
  );
});

SalesPlanForms.propTypes = {
  property: PropTypes.object.isRequired,
  specificationArea: PropTypes.number,
  choices: PropTypes.object.isRequired,
  initialized: PropTypes.func.isRequired,
  salesplan: PropTypes.object.isRequired,
  setOriginPropertyData: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  toggleViewport: PropTypes.func.isRequired,
  formPaperVariant: PropTypes.string,
  formPaperElevation: PropTypes.number,
};

SalesPlanForms.defaultProps = {
  property: {},
  choices: {},
  disabled: false,
};

const SalesPlanForm = React.memo(function salesPlanForm(props) {
  const { commonInputProps, labels, rules, index, choices, setValue, handleRemoveForSalesPlan, handleOverwriteForSalesPlan, isModal, getValues} = props
  return (
    <Grid container direction="row" spacing={1} columns={isModal ? 12 : 13}>
      {index !== undefined &&
        <>
          <HiddenInput
            {...commonInputProps}
            name={`salesplan.salesplandetail_set.${index}.id`}
          />
          <Grid item xs={isModal ? 4 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.grade`}
              rules={rules.salesplan.salesplandetail_set.grade}
              label={labels.grade}
              isLabelInside={!isModal}
              choices={choices?.grades}
              disabled={props.disabled || !getValues(`salesplan.salesplandetail_set.${index}.is_editable_grade`)}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 4 : 1}>
            <TextField
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.building_no`}
              label={labels.building_no}
              isLabelInside={!isModal}
              rules={rules.salesplan.salesplandetail_set.building_no}
              disabled={true}
            />
          </Grid>
          <Grid item xs={isModal ? 4 : 1}>
            <TextField
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.section`}
              label={labels.section}
              isLabelInside={!isModal}
              rules={rules.salesplan.salesplandetail_set.section}
              disabled={true}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <TextField
              {...commonInputProps}
              type="number"
              name={`salesplan.salesplandetail_set.${index}.floor_number`}
              label={labels.floor_number}
              isLabelInside={!isModal}
              rules={rules.salesplan.salesplandetail_set.floor_number}
              disabled={props.disabled}
              color="yellow"
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.rb`}
              rules={rules.salesplan.salesplandetail_set.rb}
              label={labels.rb}
              isLabelInside={!isModal}
              choices={choices?.availability}
              disabled={props.disabled || !getValues(`salesplan.salesplandetail_set.${index}.is_editable_rb`)}
              setValue={setValue}
              color="yellow"
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.garage`}
              rules={rules.salesplan.salesplandetail_set.garage}
              label={labels.garage}
              isLabelInside={!isModal}
              choices={choices?.garage}
              disabled={props.disabled}
              setValue={setValue}
              color="yellow"
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.bp`}
              rules={rules.salesplan.salesplandetail_set.bp}
              label={labels.bp}
              isLabelInside={!isModal}
              choices={choices?.availability}
              disabled={true}
              setValue={setValue}
              // color="yellow"
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <TextField
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.bp_tsubo`}
              label={labels.bp_tsubo}
              isLabelInside={!isModal}
              type="float"
              disabled={props.disabled || !getValues(`salesplan.salesplandetail_set.${index}.is_editable_bp`)}
              rules={rules.salesplan.salesplandetail_set.bp_tsubo}
              color="yellow"
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.direction`}
              rules={rules.salesplan.salesplandetail_set.direction}
              label={labels.direction}
              isLabelInside={!isModal}
              choices={choices?.direction}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.room_layout`}
              rules={rules.salesplan.salesplandetail_set.room_layout}
              label={labels.room_layout}
              isLabelInside={!isModal}
              choices={choices?.room_layout}
              disabled={props.disabled}
              setValue={setValue}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <SelectForm
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.dive`}
              rules={rules.salesplan.salesplandetail_set.dive}
              label={labels.dive}
              isLabelInside={!isModal}
              choices={choices?.dive}
              disabled={props.disabled || !getValues(`salesplan.salesplandetail_set.${index}.is_editable_dive`)}
              setValue={setValue}
              color="yellow"
            />
          </Grid>
          {!isModal && <Grid item xs={2}/>}
          <Grid item xs={isModal ? 6 : 1}>
            <TextField
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.land_tsubo`}
              label={labels.land_tsubo}
              isLabelInside={!isModal}
              type="float"
              disabled={true}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <TextField
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.land_area`}
              rules={rules.salesplan.salesplandetail_set.land_area}
              label={labels.land_area}
              isLabelInside={!isModal}
              type="float"
              disabled={props.disabled}
              color="yellow"
            />
          </Grid>
          
          <Grid item xs={isModal ? 6 : 1}>
            <TextField
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.building_tsubo`}
              label={labels.building_tsubo}
              isLabelInside={!isModal}
              rules={rules.salesplan.salesplandetail_set.building_tsubo}
              type="float"
              disabled={props.disabled}
              color="yellow"
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <TextField
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.building_area`}
              label={labels.building_area}
              isLabelInside={!isModal}
              type="float"
              disabled={true}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <TextField
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.resale_price_including_building`}
              rules={rules.salesplan.salesplandetail_set.resale_price_including_building}
              label={labels.resale_price_including_building}
              isLabelInside={!isModal}
              type="number"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">万円</InputAdornment>
                ),
              }}
              disabled={props.disabled}
              color="yellow"
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <TextField
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.land_price`}
              type="number"
              disabled={true}
              label={labels.land_price}
              isLabelInside={!isModal}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">万円</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <TextField
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.land_unit_price`}
              type="number"
              disabled={true}
              label={labels.land_unit_price}
              isLabelInside={!isModal}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">万円</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <TextField
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.total_floor_unit_price`}
              type="number"
              label={labels.total_floor_unit_price}
              isLabelInside={!isModal}
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">万円</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <TextField
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.building_price`}
              type="number"
              label={labels.building_price}
              isLabelInside={!isModal}
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">万円</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 6 : 1}>
            <TextField
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.construction_price`}
              type="number"
              label={labels.construction_price}
              isLabelInside={!isModal}
              disabled={true}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">万円</InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 1}>
            <TextField
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.center_manager_price`}
              type="number"
              label={labels.center_manager_price}
              isLabelInside={!isModal}
              rules={rules.salesplan.salesplandetail_set.center_manager_price}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">万円</InputAdornment>
                ),
              }}
              disabled={props.disabled}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 1}>
            <TextField
              {...commonInputProps}
              name={`salesplan.salesplandetail_set.${index}.sales_manager_price`}
              type="number"
              label={labels.sales_manager_price}
              isLabelInside={!isModal}
              rules={rules.salesplan.salesplandetail_set.sales_manager_price}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">万円</InputAdornment>
                ),
              }}
              disabled={props.disabled}
            />
          </Grid>
          <Grid item xs={isModal ? 12 : 1}>
            <Stack direction="row" spacing={1} justifyContent="center">
              <DeleteButton
                onClick={() => handleRemoveForSalesPlan(index)}
                disabled={props.disabled}
              />
              {index !== 0 &&
                <CopyButton
                  title="1件目のデータをコピー"
                  onClick={() => handleOverwriteForSalesPlan(index)}
                  disabled={props.disabled}
                />
              }
            </Stack>
          </Grid>
        </>
      }
    </Grid>
  )
})
SalesPlanForm.propTypes = {
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  choices: PropTypes.object.isRequired,
  index: PropTypes.number,
  setValue: PropTypes.func.isRequired,
  handleRemoveForSalesPlan: PropTypes.func.isRequired,
  handleOverwriteForSalesPlan: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  getValues: PropTypes.func.isRequired,
}

const SalesPlanHeaderForm = React.memo(function salesPlanHeaderForm(props) {
  const {commonInputProps, isModal, rules, labels} = props
  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={isModal ? 12 : 6}>
        <TextField
          {...commonInputProps}
          label={labels.center_manager_price}
          name={`salesplan.center_manager_name`}
          rules={rules.salesplan.center_manager_name}
          disabled={props.disabled}
        />
      </Grid>
      <Grid item xs={isModal ? 12 : 6}>
        <TextField
          {...commonInputProps}
          label={labels.sales_manager_price}
          name={`salesplan.sales_manager_name`}
          rules={rules.salesplan.sales_manager_name}
          disabled={props.disabled}
        />
      </Grid>
    </Grid>
  )
})
SalesPlanHeaderForm.propTypes = {
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
  rules: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
}

const SalesPlanTotal = React.memo(function salesPlanTotal(props) {
  const {commonInputProps, isModal, labels} = props
  return (
    <Grid container direction="row" spacing={1} columns={13}>
      {!isModal &&
        <Grid item xs={13}>
          <Typography variant="subtitle1">
            合計
          </Typography>
        </Grid>
      }
      <Grid item xs={isModal ? 6.5 : 1}>
        <TextField
          {...commonInputProps}
          name={`salesplan.total_land_tsubo`}
          label={labels.land_tsubo}
          isLabelInside={!isModal}
          type="float"
          disabled={true}
        />
      </Grid>
      <Grid item xs={isModal ? 6.5 : 1}>
        <TextField
          {...commonInputProps}
          name={`salesplan.total_land_area`}
          label={labels.land_area}
          isLabelInside={!isModal}
          type="float"
          disabled={true}
        />
      </Grid>
      <Grid item xs={isModal ? 6.5 : 1}>
        <TextField
          {...commonInputProps}
          name={`salesplan.total_building_tsubo`}
          label={labels.building_tsubo}
          isLabelInside={!isModal}
          type="float"
          disabled={true}
        />
      </Grid>
      <Grid item xs={isModal ? 6.5 : 1}>
        <TextField
          {...commonInputProps}
          name={`salesplan.total_building_area`}
          label={labels.building_area}
          isLabelInside={!isModal}
          type="float"
          disabled={true}
        />
      </Grid>
      <Grid item xs={isModal ? 6.5 : 1}>
        <TextField
          {...commonInputProps}
          name={`salesplan.total_resale_price_including_building`}
          label={labels.resale_price_including_building}
          isLabelInside={!isModal}
          type="float"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">万円</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={isModal ? 6.5 : 1}>
        <TextField
          {...commonInputProps}
          name={`salesplan.total_land_price`}
          label={labels.land_price}
          isLabelInside={!isModal}
          type="number"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">万円</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={isModal ? 6.5 : 1}>
        <TextField
          {...commonInputProps}
          name={`salesplan.total_land_unit_price`}
          label={labels.land_unit_price}
          isLabelInside={!isModal}
          type="number"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">万円</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={isModal ? 6.5 : 1}>
        <TextField
          {...commonInputProps}
          name={`salesplan.total_floor_unit_price`}
          label={labels.total_floor_unit_price}
          isLabelInside={!isModal}
          type="number"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">万円</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={isModal ? 6.5 : 1}>
        <TextField
          {...commonInputProps}
          name={`salesplan.total_building_price`}
          label={labels.building_price}
          isLabelInside={!isModal}
          type="number"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">万円</InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={isModal ? 6.5 : 1}>
        <TextField
          {...commonInputProps}
          name={`salesplan.total_construction_price`}
          label={labels.construction_price}
          isLabelInside={!isModal}
          type="number"
          disabled={true}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">万円</InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  )
})
SalesPlanTotal.propTypes = {
  isModal: PropTypes.bool.isRequired,
  commonInputProps: PropTypes.object.isRequired,
  labels: PropTypes.object.isRequired,
}